import { ReactNode } from "react";

export interface GradientContainerProps {
  children?: ReactNode,
  light?: boolean,
  gradientDirection?: string,
  overrideClassName?: string,
}

const GradientContainer = ({ children, light = false, gradientDirection = 'bg-gradient-to-tl', overrideClassName }: GradientContainerProps) => <div
  className={`${gradientDirection} ${light
    ? 'from-[#86EFAC] to-[#67E8F9]'
    : 'from-[#22C55E] to-[#06B6D4]'}
    ${overrideClassName}`
  }
>
  {children}
</div>

export default GradientContainer;