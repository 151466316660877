/* eslint-disable @next/next/no-img-element */
import Link from "next/link"
import TextGradient from '@app/components/gradient/gradient-text';
import GradientContainer from '@app/components/gradient/gradient-container';
import Input from '@app/components/input'
import Checkbox from '@app/components/checkbox'
import Button from '@app/components/button'

const LoginPage = () => {
  return (
    <div className="xxxs:flex-none xxxs:px-8 md:px-0 md:flex flex-row">
      <div className="w-full lg:basis-1/2 flex flex-col items-center">
        <div className="w-full py-10 md:py-12 lg:pt-24 md:px-10 lg:px-14 xl:px-32 
        3xl:!px-44 4xl:!px-60">
          <h3 className="h3 text-background-primary-dark">Log in</h3>
          <GradientContainer overrideClassName="w-20 h-2 mt-3" />
          <p className="label-sm mt-6 text-[#667085]">
            Welcome back! Please enter your details.
          </p>
          <div className="mt-8 w-full">
            <Input
              label="Email"
              labelClassName="label-md text-text-color-high-em"
              placeholder="Enter your email"
            />
            <Input
              type="password"
              label="Password"
              labelClassName="label-md text-text-color-high-em mt-5"
              placeholder="••••••••"
            />
          </div>
          <Button className="w-full mt-7">Sign in</Button>
        </div>
      </div>
      <div className="xxxs:hidden lg:basis-1/2 bg-background-low-em lg:flex flex-col justify-center items-center space-y-10 pt-6">
        <TextGradient>
          <h1 className="h1">
            Partners
          </h1>
        </TextGradient>
        <img className="w-[500px] h-[500px]" src="images/login/partners.png" alt="" />
      </div>
    </div>
  )
}

export default LoginPage
