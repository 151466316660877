import { InputHTMLAttributes } from 'react'

// initial input field implementation
export interface InputFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string
  placeholder?: string
  labelClassName?: string
  inputClassName?: string
}

const Input = ({
  type,
  label,
  placeholder,
  labelClassName,
  inputClassName,
  ...inputProps
}: InputFieldProps) => {
  const labelDefaultClassName = "text-text-color-high-em"
  const inputDefaultClassName = "w-full rounded-lg border-[#D0D5DD] mt-1 text-text-color-high-em"

  return (
    <div>
      <p className={labelClassName ? labelClassName : labelDefaultClassName}>{label}</p>
      <input
        type={type ? type : 'text'}
        placeholder={placeholder}
        className={`${inputDefaultClassName} ${inputClassName}`}
        {...inputProps}
      />
    </div>
  )
}

export default Input
